@import 'src/styles/variables';

.container {
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  margin-right: -5px;
  margin-bottom: -5px;

  @include for-size(phone-only) {
    max-width: calc(100vw - 80px);
  }

  &.wpContainer {
    @include for-size(phone-only) {
      max-width: 100%;
    }
  }
}

.centered {
  justify-content: center;
}

.item {
  opacity: 0.8;
  display: inline-block;
  position: relative;
  margin: 0 5px 5px 0;
  border-radius: 4px;
  color: $dark-blue-shadow;
  background-color: $white-shadow;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
  max-width: 100%;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  line-height: 18px;
  padding: 4px 6px;
}
